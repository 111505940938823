import {useNavigate, useSearchParams} from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {cardSx, containerSx} from "../styles";
import {Box, Button, Container} from "@mui/material";
import useTranslationMessages from "src/translations/useMessages";
import CenteredSpinner from "src/components/CenteredSpinner";
import {useSnackbar} from "notistack";
import {LanguageSelector} from "../LanguageSelector";
import {useCallback, useEffect} from "react";
import AcceptInviteForm from "./form";
import {useFormData} from "../../../components/Form/useFormData";
import PsychologyCenterApi, {AcceptInviteInput} from "../../../reduxV2/entities/PsychologyCenter";
import Grid from "@mui/material/Grid";
import InteractiveBackground from "../../../components/InteractiveBackground";
import {useIsUserLoggedIn, useLogout, useUserId} from "../../../components/AuthProvider/store";

const initialUserData = {
    firstName: "",
    lastName: "",
    userInviteId: 0,
    verifyToken: "",
    password: ""
};

export const AcceptInvite = () => {
    const t = useTranslationMessages();
    const navigate = useNavigate();
    const isLoggedIn = useIsUserLoggedIn();
    const logout = useLogout();
    const {enqueueSnackbar} = useSnackbar();
    const [searchParams] = useSearchParams();
    const [mutate, {data, isLoading, isSuccess, isError, reset: resetMutation}] =
        PsychologyCenterApi.useAcceptInviteMutation();

    const {
        values,
        errors,
        setValue,
        setError,
        reset: resetForm,
    } = useFormData<AcceptInviteInput>({
        ...initialUserData,
        userInviteId: +searchParams.get('inviteId'),
        verifyToken: searchParams.get('token')?.replace(/ /g, '+')
    });

    const resetFormState = useCallback(() => {
        resetForm();
        resetMutation();
    }, [resetForm, resetMutation]);

    const handleActivate = () => {
        mutate({acceptInvite: values}).then((res: any) => {
            if ("error" in res) {
                enqueueSnackbar(res.error.data.errors?.toString() || t.smthWentWrong, {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(t.activationSuccessfullMsg, {
                    variant: "success",
                });
                setTimeout(() => {
                    navigate('/login')
                }, 3000);
            }
        });
    }

    useEffect(() => {
        if (isLoggedIn) {
            logout();
        }
    }, [isLoggedIn]);


    return (
        <Box sx={containerSx}>
            <InteractiveBackground/>
            <Typography
                variant="h2"
                align="center"
                color="primary"
                marginBottom={5}>
                Measurme
            </Typography>
            <Card sx={cardSx}>
                <CardContent
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Typography
                        variant="h5"
                        align="center"
                        marginBottom={1}
                        sx={{flex: 0}}>
                        {t.activatingUser}
                    </Typography>
                    {isLoading ? (<CenteredSpinner sx={{flex: 1}}/>) : (
                        <Container>

                            <AcceptInviteForm values={values} errors={errors} setValue={setValue}
                                              setError={setError}/>

                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                marginTop={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!values.firstName || !values.lastName || !values.password || isLoading}
                                    onClick={() => {
                                        handleActivate()
                                        resetFormState()
                                    }}
                                    sx={{m: 1}}>
                                    {t.create}
                                </Button>
                            </Grid>
                        </Container>
                    )}
                </CardContent>
            </Card>
            <LanguageSelector/>
        </Box>
    );
};

export default AcceptInvite;
